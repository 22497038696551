import React, { FC } from 'react';
import loadable from '@loadable/component';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import classNames from 'classnames';
import UmbracoImage from 'gatsby-theme-dettol/src/components/common/Image/UmbracoImage';

// Utils
import getDropDownItem from 'utils/dropDownControl';

// Styles
import 'gatsby-theme-dettol/src/components/HowToUse/HowToUse.scss';
import 'gatsby-theme-dettol/src/components/SafetyInstructions/SafetyInstructions.scss';
import 'gatsby-theme-dettol/src/components/StoryTelling/StoryTelling.scss';
import './DDSProductPage.scss';

// Models
import { IWrapperProps } from 'components/common/Article/models.d';
import { ProductBlockType } from 'gatsby-theme-dettol/src/templates/PurposePage/models.d';
import { TDDSBodyRendererComponentProps } from './models.d';

enum DDSProductPageBlocks {
  TEXT_BLOCK = 'Text Block',
  TITLE_BLOCK = 'Title block (regular + bold)',
  VIDEO_BLOCK = 'Video Block',
  PRODUCT_FEATURES_BLOCK = 'Product Features',
  DDS_PRODUCT_BLOCK = 'DDS Product Block',
}

const Wrapper: FC<IWrapperProps> = ({ children, isFullWidth }) => (
  <Container className="container-fluid">
    <Row className="justify-content-md-center">
      <Col sm={12} md={12} lg={isFullWidth ? 12 : 8}>
        {children}
      </Col>
    </Row>
  </Container>
);

const DDSBodyRenderer: FC<TDDSBodyRendererComponentProps> = ({ block }) => {
  switch (block.structure) {
    case DDSProductPageBlocks.TITLE_BLOCK: {
      const HTag = loadable(() => import('../../components/common/HTag'));

      return (
        <Wrapper isFullWidth key={`TEXT_BLOCK${block.articleSectionDescription?.substr(0, 10)}`}>
          <HTag size={3} regularText={block.regularText} boldText={block.boldText} centered />
        </Wrapper>
      );
    }
    case DDSProductPageBlocks.TEXT_BLOCK: {
      const InnerHtml = loadable(() => import('components/common/DangerouslySetInnerHtml'));

      return (
        <Wrapper isFullWidth key={`TEXT_BLOCK${block.articleSectionDescription?.substr(0, 10)}`}>
          <InnerHtml html={block.articleSectionDescription} className="dt-dds-text-block" />
        </Wrapper>
      );
    }
    case DDSProductPageBlocks.VIDEO_BLOCK: {
      const MediaInfoBox = loadable(() => import('components/common/MediaInfoBox/MediaInfoBox'));
      const Media = loadable(() => import('components/common/MediaInfoBox/Media/Media'));
      const Label = loadable(() => import('components/common/Label'));

      return (
        <Wrapper isFullWidth key={`TEXT_BLOCK${block.articleSectionDescription?.substr(0, 10)}`}>
          <div className="dt-how-to-use__video">
            <Label type="info__video">{block.actionLabelText}</Label>
            <MediaInfoBox isFullWidth>
              <Media video={block.videoUrl}>
                <UmbracoImage image={block.image} alt={block.imageAlt} />
              </Media>
            </MediaInfoBox>
          </div>
        </Wrapper>
      );
    }
    case DDSProductPageBlocks.PRODUCT_FEATURES_BLOCK: {
      const HTag = loadable(() => import('components/common/HTag'));
      const SList = loadable(() => import('components/SafetyInstructions/SafetyInstructionsList'));

      return (
        <Wrapper key={`product-${block.structure}-body-block-${block.titleRegular}`}>
          <div className="dt-safety-instructions">
            <HTag size={3} regularText={block.regularText} boldText={block.boldText} />
            {block.productFeatures ? <SList data={block.productFeatures} /> : null}
          </div>
        </Wrapper>
      );
    }
    case DDSProductPageBlocks.DDS_PRODUCT_BLOCK: {
      const HTag = loadable(() => import('components/common/HTag'));
      const InnerHtml = loadable(() => import('components/common/DangerouslySetInnerHtml'));
      const swap = getDropDownItem(block.blockType) === ProductBlockType.Left;
      const classes = classNames(
        'dt-story-telling dt-container-wrapper dt-story-telling--rounded dt-story-telling--light',
        {
          'dt-story-telling--swap': swap,
        }
      );

      return (
        <section className={classes}>
          <Container className="dt-story-telling__container" fluid>
            <div className="dt-story-telling__img-holder">
              <UmbracoImage
                image={block.image}
                alt={block.imageAlt}
                className="dt-story-telling__img"
              />
            </div>

            <div className="dt-story-telling__content">
              <HTag
                newline
                underline
                size={2}
                regularText={block.regularText}
                boldText={block.boldText}
              />
              <InnerHtml html={block.description} />
            </div>
          </Container>
        </section>
      );
    }
    default:
      return null;
  }
};

export default DDSBodyRenderer;
