import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { TDDSProductProps } from './models';
import Layout from '../../components/Layout';
import Banner from '../../components/common/Banner';
import Expertise from '../../components/Expertise';
import RelatedProducts from '../../components/RelatedProducts';
import DDSBodyRenderer from './DDSBodyRenderer';

import 'styles/main.scss';
import { isBrowser } from 'utils/browser';

const DDSProductPage: FC<TDDSProductProps> = ({
  data: {
    footerNavigation,
    homepageSettings,
    languageSelector,
    siteSettings,
    mobileAppPromoBanner,
    menu,
    ddsProductPage,
    relatedProducts: { nodes: relatedProducts },
    relatedArticles: { nodes: relatedArticles },
  },
}) => {
  const {
    featuredImage,
    articlesCarousel,
    productsCarousel,
    bodyBlock,
    seoMetaTitle,
    seoMetaKeywords,
    seoMetaDescription,
    link,
  } = ddsProductPage;
  const { regularTitle, descriptionText, image, boldTitle } = featuredImage[0];
  const {
    regularTitle: articlesCarouselTitleRegularText,
    boldTitle: articlesCarouselTitleBoldText,
  } = articlesCarousel[0];

  const {
    regularTitle: productsCarouselTitleRegularText,
    boldTitle: productsCarouselTitleBoldText,
  } = productsCarousel[0];

  const pathNameRaw = isBrowser() ? window.location.pathname : '';
  const pathName = pathNameRaw.replaceAll('/', '');

  return (
    <Layout
      headerTransparent={false}
      seo={{ seoMetaTitle, seoMetaDescription, seoMetaKeywords }}
      siteSettings={siteSettings}
      menu={menu}
      footerNavigation={footerNavigation}
      mobileAppPromoBanner={mobileAppPromoBanner}
      homepageSettings={homepageSettings}
      languageSelector={languageSelector}
      url={link}
      className={pathName}
    >
      <Banner
        newline
        title={{ regularText: regularTitle, boldText: boldTitle }}
        text={descriptionText}
        img={image}
      />
      {bodyBlock.length ? (
        <section className="dt-dds-body-block-container">
          {bodyBlock.map((block) => (
            <DDSBodyRenderer key={block.structure} block={{ ...block }} />
          ))}
        </section>
      ) : null}
      {relatedArticles.length ? (
        <Expertise
          title={{
            regularText: articlesCarouselTitleRegularText,
            boldText: articlesCarouselTitleBoldText,
          }}
          articles={relatedArticles}
          sectionLandmark={`${articlesCarouselTitleRegularText} ${articlesCarouselTitleBoldText}`}
        />
      ) : null}

      {relatedProducts.length ? (
        <RelatedProducts
          products={relatedProducts}
          title={{
            regularText: productsCarouselTitleRegularText ?? 'Related',
            boldText: productsCarouselTitleBoldText ?? 'Products',
          }}
        />
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query DDSProductPageQuery(
    $articlesLimit: Int
    $lang: String
    $link: String
    $productsLimit: Int
    $relatedArticlesLinks: [String]
    $relatedProductsSkus: [String]
  ) {
    ddsProductPage(link: { eq: $link }, lang: { eq: $lang }) {
      link
      seoMetaTitle
      seoMetaKeywords
      seoMetaDescription
      featuredImage {
        boldTitle
        descriptionText
        imageAlt
        regularTitle
        sectionLandmark
        image {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
      }
      bodyBlock {
        actionLabelText
        articleSectionDescription
        blockType
        boldText
        description
        image {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        imageAlt
        productFeatures {
          boldText
          regularText
          properties {
            description
            iconName
          }
        }
        regularText
        structure
        videoUrl
      }
      articlesCarousel {
        boldTitle
        regularTitle
        sectionLandmark
      }
      productsCarousel {
        boldTitle
        regularTitle
        sectionLandmark
      }
    }
    languageSelector(lang: { eq: $lang }) {
      ...FragmentLanguageSwitcher
    }
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
    menu(lang: { eq: $lang }) {
      ...FragmentHeader
    }
    footerNavigation(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    mobileAppPromoBanner(lang: { eq: $lang }) {
      ...FragmentMobileAppPromoBanner
    }
    homepageSettings(lang: { eq: $lang }) {
      ...FragmentHomepageSettings
    }
    relatedArticles: allUmbracoArticle(
      limit: $articlesLimit
      filter: { link: { in: $relatedArticlesLinks } }
    ) {
      nodes {
        title
        link
        intro
        label
        image {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
      }
    }
    relatedProducts: allUmbracoProduct(
      limit: $productsLimit
      filter: { sku: { in: $relatedProductsSkus }, lang: { eq: $lang } }
    ) {
      nodes {
        shortNameRegular
        shortNameBold
        recognisableFeature
        name
        link
        id
        productColor
        size
        localHeroImage {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        cardImage {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
      }
    }
  }
`;

export default DDSProductPage;
